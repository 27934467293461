import React, { FC, JSX, useState } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { TypographyStyle } from '../../views/deals/components/SettingMenu';
import Box from '@mui/material/Box';
import { useAuthStore, useRootStore } from '../../store/MobxStoreProvider';
import { AppUserGroupCodes } from '../../types/AppUserGroup';
import { Bill } from '../../api/marketx';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
import { Link } from '@mx-ui/ui';

interface BillListMenuProps {
  bill: Bill;
}

export const BillListMenu: FC<BillListMenuProps> = observer(({ bill }): JSX.Element => {
  const rootStore = useRootStore();
  const snackbarStore = rootStore.getSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isReservePrioritizeBtnDisabled, setIsReservePrioritizeBtnDisabled] = React.useState(false);
  const authStore = useAuthStore();
  const open = Boolean(anchorEl);
  const apiStore = useRootStore().getApiStore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
  };
  const handleDialogOpen = (): void => {
    setIsDialogOpen(true);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleReservePrioritize = (): void => {
    setAnchorEl(null);
    setIsDialogOpen(false);
    apiStore
      .apiClientBill()
      .billSoftReservePrioritize(bill.documentNumber)
      .then(() => {
        snackbarStore.showSuccess('Счет приоритизирован в очереди');
        setIsReservePrioritizeBtnDisabled(true);
      })
      .catch(() => {
        snackbarStore.showError('Ошибка приоритизации');
      });
  };
  const isBillFromMx = /BL/.test(bill.documentNumber); // проверка - является ли счет MX счетом
  const printBillAccess = authStore.inAnyGroup([
    AppUserGroupCodes.ADMIN,
    AppUserGroupCodes.OFFICE_CHIEF,
    AppUserGroupCodes.SELLER,
    AppUserGroupCodes.SELLER_CHIEF,
    AppUserGroupCodes.DIVISION_CHIEF,
  ]);
  const reservePrioritizeAccess = isBillFromMx && authStore.profile.resources.some(i => i.uri === 'shop://bill/softReserve/prioritize');
  return (
    <Box display="flex" alignItems={'center'} flexDirection={'row'} position={'absolute'} right={'10px'} top={'0px'}>
      <IconButton color="primary" onClick={handleClick} component="span" size="large">
        <MoreVertSharpIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem disabled={!printBillAccess}>
          <Link
            href={`/app/print-documents/specification/bills/${bill.documentNumber}`}
            variant="body1"
            color="textPrimary"
            sx={TypographyStyle}
          >
            Сформировать спецификацию
          </Link>
        </MenuItem>
        <MenuItem disabled={!printBillAccess}>
          <Link
            href={`/app/print-documents/bills/${bill.documentNumber}`}
            variant="body1"
            color="textPrimary"
            target="_blank"
            sx={TypographyStyle}
          >
            Сформировать счет на оплату
          </Link>
        </MenuItem>
        <MenuItem disabled={!reservePrioritizeAccess || isReservePrioritizeBtnDisabled} onClick={handleDialogOpen}>
          <Typography variant="body1" color="textPrimary" component="a" sx={TypographyStyle}>
            Назначить приоритетное резервирование
          </Typography>
        </MenuItem>
      </Menu>
      <DialogContainer
        open={isDialogOpen}
        onClose={handleDialogClose}
        handleSuccess={handleReservePrioritize}
        dialogContent={
          <Typography variant={'body1'}>
            Эта операция позволит данному счету в приоритетном порядке получить резерв из свободного остатка, что может привести к отмене
            сформированных резервов в других счетах.
          </Typography>
        }
        dialogTitle={'Назначить приоритетное резервирование'}
        canselBtnText={'Отменить'}
        successBtnText={'Назначить'}
      />
    </Box>
  );
});
