import React, { FC, JSX, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { BillItemStore } from '../../store/Documents/BillItemStore';
import { LoadingSummaryDeliverySkeleton } from '../../views/deals/DealSummary/DealSummaryDelivery';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import { CustomIconTabs } from '../Deals/CustomIconTabs/CustomIconTabs';
import { formatValueCurPayment } from '../../views/report/payment/PaymentTabPanelClients';
import { TextFieldInfo } from '../ui/TextFieldInfo';
import TuneIcon from '@mui/icons-material/Tune';
import { TabSelectWrapper } from '../Deals/CustomIconTabs/TabSelectWrapper';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { MxLabel } from '@mx-ui/ui';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import { BillSummaryPartners } from './BillSummaryPartners';
import { BillAdditionalTabComment } from './BillPositionItem/BillAdditionalTabComent';
import { useAuthStore } from '../../store/MobxStoreProvider';
import { BillSummaryReserveControl } from './BillSummaryReserveControll';

export const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
          backgroundColor: 'grey',
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        backgroundColor: 'grey',
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      boxShadow: '0px 0px 3px grey',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#f5605e',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })
);

interface BillSummaryDeliveryProps {
  billStore: BillItemStore;
}
const minHeightForTabSections = '119px';
export const BillSummaryDelivery: FC<BillSummaryDeliveryProps> = observer(({ billStore }): JSX.Element => {
  const bill = billStore.item;
  const hasNewPartnersAccess = useAuthStore().isNewPartnersSchemaVisible;
  const [selectedDeliveryTab, setSelectedDeliveryTab] = useState(0);
  const headerTabsValue = useMemo(() => {
    return [
      {
        label: ``,
        tabPanelIndex: 0,
        icon: <TuneIcon />,
        badgeOn: false,
      },
      { label: ``, tabPanelIndex: 1, disabled: !hasNewPartnersAccess, icon: <HubRoundedIcon />, badgeOn: false },
      { label: ``, tabPanelIndex: 2, disabled: false, icon: <LocalShippingOutlinedIcon />, badgeOn: false },
      {
        label: ``,
        tabPanelIndex: 3,
        disabled: false,
        icon: <ChatOutlinedIcon />,
        badgeOn: !!billStore.temporaryComments?.['billSummary']?.comment,
      },
      { label: ``, tabPanelIndex: 4, disabled: false, icon: <InfoOutlinedIcon />, badgeOn: false },
      { label: ``, tabPanelIndex: 5, disabled: false, icon: <CurrencyRubleIcon />, badgeOn: false },
    ];
  }, [billStore, billStore.temporaryComments?.['billSummary']?.comment]);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<unknown>, newValue: number) => {
      setSelectedDeliveryTab(newValue);
    },
    [setSelectedDeliveryTab]
  );

  if (!billStore.isLoaded || !billStore.item?.state?.code) {
    return <LoadingSummaryDeliverySkeleton />;
  }

  return (
    <Grid item md={6} lg={4} xs={12}>
      <Paper sx={{ padding: 1, position: 'relative', height: 'calc(100% - 60px)' }}>
        <Grid container flexDirection={'column'} height={'100%'}>
          <Grid item sx={{ width: '100%', overflow: 'hidden', height: '66px' }} className="withCustomScroll">
            <Grid pl={2} className="withCustomScroll" sx={{ pl: '5px', display: 'grid', gridTemplateColumns: 'minmax(300px, 1fr)' }}>
              <CustomIconTabs
                handleToggleButtonChange={handleTabChange}
                toggleButtonValue={selectedDeliveryTab}
                tabsValue={headerTabsValue}
              />
            </Grid>
          </Grid>
          <Grid item flex={'1 1 auto'}>
            <TabSelectWrapper>
              {selectedDeliveryTab === 0 && (
                <BillSummaryReserveControl billStore={billStore} minHeightForTabSections={minHeightForTabSections} />
              )}
              {selectedDeliveryTab === 1 && <BillSummaryPartners billStore={billStore} minHeightForTabSections={minHeightForTabSections} />}
              {selectedDeliveryTab === 2 && (
                <Grid container minHeight={minHeightForTabSections}>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        Способ доставки
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        {bill?.deliveryType?.name || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {selectedDeliveryTab === 3 && (
                <Grid container minHeight={minHeightForTabSections}>
                  <BillAdditionalTabComment billStore={billStore} />
                </Grid>
              )}
              {selectedDeliveryTab === 4 && (
                <Grid container minHeight={minHeightForTabSections}>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        Статус
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <MxLabel
                        style={{ display: 'inline', paddingTop: '4px' }}
                        color={
                          bill.customer?.activityStatusCode === 'not_active'
                            ? 'error'
                            : bill.customer?.activityStatusCode === 'approval'
                            ? 'warning'
                            : 'success'
                        }
                      >
                        {bill.customer?.activityStatusTitle || 'Неизвестно'}
                      </MxLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        Сегмент
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        {bill.customer?.segmentCode || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        Отрасль
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        {bill.customer?.occupationTitle || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                  {bill?.customer?.mainEmployee && (
                    <Grid item xs={12} container>
                      <Grid item xs={6}>
                        <Typography color="text.secondary" variant="body2">
                          Реализатор
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color="text.secondary" variant="body2">
                          {bill?.customer?.mainEmployee?.surname || ''} {bill?.customer?.mainEmployee?.name || ''}{' '}
                          {bill?.customer?.mainEmployee?.patronymic || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
              {selectedDeliveryTab === 5 && (
                <Grid container minHeight={minHeightForTabSections}>
                  <Grid container alignItems={'center'}>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        ДЗ
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        {formatValueCurPayment(bill.paymentSummary?.receivablesAmount)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container alignItems={'center'}>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        Оплачено {bill.paymentSummary?.debtReliefAmount > 0 && <>(включая списание долга)</>}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        {formatValueCurPayment(bill.paymentSummary?.paidAmount)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {bill.paymentSummary?.debtReliefAmount > 0 && (
                    <Grid container alignItems={'center'}>
                      <Grid item xs={6}>
                        <Typography color="text.secondary" variant="body2">
                          Списание долга
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography color="text.secondary" variant="body2">
                          {formatValueCurPayment(bill.paymentSummary?.debtReliefAmount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container alignItems={'center'}>
                    <Grid item xs={6}>
                      <Typography color="text.secondary" variant="body2">
                        Просрочено
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color={bill?.paymentSummary?.overdueReceivablesAmount ? 'error' : 'text.secondary'}>
                        {formatValueCurPayment(bill?.paymentSummary?.overdueReceivablesAmount)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </TabSelectWrapper>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ padding: 1, mt: 2, position: 'relative' }}>
        <Grid container spacing={0}>
          <Grid item xs={12} container>
            <TextFieldInfo value={bill.consignee?.title} label={'Грузополучатель'} fullWidth />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
});
