import React, { FC, JSX, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export type SwitcherListItem = {
  isCurrent: boolean;
  value: string;
  type: SwitcherStatesType;
};
interface ThreeStateSwitcherProps {
  switcherList: SwitcherListItem[];
  onChange: (v: string) => void;
  disabled?: boolean;
}
const ThreeStateSwitcherStyled = styled('div')`
  .container {
    position: relative;
    display: flex;
    width: 63px;
    height: 26px;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 16%), 0px -1px 1px 0px rgb(0 0 0 / 12%), 0px 2px 1px 0px rgb(0 0 0 / 12%);
    padding: 0 2px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  .item {
    width: 50%;
    height: 100%;
  }
  .item_disabled {
    width: 0;
  }
  .radio_item {
    width: 0;
    height: 0;
    visibility: hidden;
  }
  .radio_label {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .radio_label:focus {
    outline: none !important;
  }
  .left {
    background: #f5605e;
  }
  .middle {
    background: ${p => (p.theme.palette.mode === 'dark' ? '#39393D' : '#e9e9ea')};
  }
  .right {
    background: #65c466;
  }
  .container.disabled {
    background: ${p => (p.theme.palette.mode === 'dark' ? '#39393D' : '#e9e9ea')};
  }
  .toggle {
    cursor: pointer;
    position: absolute;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: ${p => (p.theme.palette.mode === 'dark' ? 'rgb(224, 224, 224)' : '#fff')};
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
  }
  .toggle_left {
    transform: translate(0px, -50%);
  }
  .toggle_middle {
    transform: translate(18px, -50%);
  }
  .toggle_right {
    transform: translate(36px, -50%);
  }
  .sideContainer {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .sideItem {
    flex: 0 0 50%;
  }
`;

type SwitcherStatesType = 'left' | 'middle' | 'right';
export const ThreeStateSwitcher: FC<ThreeStateSwitcherProps> = observer(({ switcherList, onChange, disabled = false }): JSX.Element => {
  const matchesExSmall = useMediaQuery('(min-width:640px)');
  const [radioState, setRadioState] = useState<SwitcherStatesType>(switcherList?.find(i => i.isCurrent)?.type || 'middle');
  const handleWrapperClick = (type: string): void => {
    if (disabled) {
      return;
    }
    if (type === 'all') {
      setRadioState(prev => {
        return prev === 'left' || prev === 'middle' ? 'right' : 'left';
      });
      return;
    }
    setRadioState(type as SwitcherStatesType);
  };
  useEffect(() => {
    const currentState = switcherList?.find(i => i.type === radioState);
    const currentValue = currentState?.isCurrent ? '' : currentState?.value;
    switcherList && onChange && currentValue && onChange(currentValue);
  }, [radioState]);

  useEffect(() => {
    const currentState = switcherList?.find(i => i.isCurrent);
    currentState?.type !== radioState && setRadioState(currentState?.type);
  }, [switcherList, radioState]);
  const title = disabled
    ? 'Недоступно'
    : radioState === 'right'
    ? 'Выключить автоматическое резервирование'
    : 'Включить автоматическое резервирование';
  return (
    <ThreeStateSwitcherStyled title={title}>
      <div
        className={`container ${radioState} ${disabled && 'disabled'}`}
        onClick={() => !matchesExSmall && handleWrapperClick('all')}
        style={{ cursor: disabled ? 'initial' : 'pointer' }}
      >
        {matchesExSmall && (
          <div className={'sideContainer'}>
            <div className={'sideItem'} onClick={() => handleWrapperClick('left')} />
            <div className={'sideItem'} onClick={() => handleWrapperClick('right')} />
          </div>
        )}
        <div className={`toggle toggle_${radioState}`} style={{ cursor: disabled ? 'initial' : 'pointer' }} />
      </div>
    </ThreeStateSwitcherStyled>
  );
});
