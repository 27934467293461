import React, { FC, JSX, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { BillItemStore } from '../../store/Documents/BillItemStore';
import { Address, ParticipantPartner } from '../../api/marketx';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { TabSelectWrapper } from '../Deals/CustomIconTabs/TabSelectWrapper';
import NearMeTwoToneIcon from '@mui/icons-material/NearMeTwoTone';
import { Link } from '@mx-ui/ui';
import { useAuthStore } from '../../store/MobxStoreProvider';

interface BillSummaryPaymentPartnerSelectProps {
  billStore: BillItemStore;
  minHeightForTabSections?: string;
}

export const BillSummaryPartners: FC<BillSummaryPaymentPartnerSelectProps> = observer(
  ({ billStore, minHeightForTabSections }): JSX.Element => {
    const hasNewPartnersAccess = useAuthStore().isNewPartnersSchemaVisible;
    const bill = billStore.item;
    const deal = billStore.dealStore.deal;
    const [consigneeValue, setConsigneeValue] = useState(bill.partners.consignee);
    const [addressValue, setAddressValue] = useState(bill.partners.consignee?.address);
    const [payerValue, setPayerValue] = useState(bill.partners.payer);

    const billPartnersStore = billStore.billPartnersStore;

    useEffect(() => {
      if (billPartnersStore && bill && !billPartnersStore.billCode) {
        billPartnersStore.loadListForBill(bill);
      }
    }, [bill, billPartnersStore]);
    const handleConsigneesChange = (newConsignee: ParticipantPartner): void => {
      if (newConsignee.code === consigneeValue?.code) {
        return;
      }
      let newAddress = null;
      billPartnersStore.reloadAddressesList(newConsignee.customerCode).then(() => {
        if (billPartnersStore.addressesList?.length === 1) {
          setAddressValue(billPartnersStore.addressesList[0]);
          newAddress = billPartnersStore.addressesList[0];
        } else {
          setAddressValue(null);
        }
        setConsigneeValue(newConsignee);
      });
      billStore.apiStore
        .apiClientBill()
        .billsItemUpdatePartner(bill.documentNumber, { participantCode: newConsignee.code, type: 'consignee' })
        .then(() => updateBillPartners(newConsignee, payerValue, newAddress));
    };
    const handleAddressChange = (newAddress: Address): void => {
      if (newAddress.code === addressValue?.code) {
        return;
      }
      setAddressValue(newAddress);
      billStore.apiStore
        .apiClientBill()
        .billsItemUpdatePartnerAddress(bill.documentNumber, {
          participantCode: consigneeValue.code,
          type: 'consignee',
          addressCode: newAddress.code,
        })
        .then(() => updateBillPartners(consigneeValue, payerValue, newAddress));
    };
    const handlePayerChange = (newPayer: ParticipantPartner): void => {
      if (newPayer.code === payerValue?.code) {
        return;
      }
      billStore.apiStore
        .apiClientBill()
        .billsItemUpdatePartner(bill.documentNumber, { participantCode: newPayer.code, type: 'payer' })
        .then(() => updateBillPartners(consigneeValue, newPayer, addressValue));
      setPayerValue(newPayer);
    };
    const updateBillPartners = (consignee: ParticipantPartner, payer: ParticipantPartner, addressV: Address): void => {
      billStore.updatePartners({
        consignee: consignee
          ? {
              ...consignee,
              address: addressV ? addressV : null,
            }
          : null,
        payer: payer ? { ...payer } : null,
      });
    };

    const mapBtnTitle =
      deal?.warehouseAddress?.latitude && addressValue?.latitude ? 'Показать маршрут' : addressValue?.latitude ? 'Показать на карте' : '';
    const mapBtnUrl =
      deal?.warehouseAddress?.latitude && addressValue?.latitude
        ? 'https://yandex.ru/maps/?rtext=' +
          deal?.warehouseAddress?.latitude +
          ',' +
          deal?.warehouseAddress?.longitude +
          '~' +
          addressValue?.latitude +
          ',' +
          addressValue?.longitude +
          '&rtt=auto'
        : addressValue?.latitude
        ? addressValue?.latitude + ',' + addressValue?.longitude + '&rtt=auto'
        : '';

    return (
      <TabSelectWrapper>
        <Grid container spacing={0} minHeight={minHeightForTabSections}>
          <Grid container flexDirection={'column'} sx={{ maxHeight: '100%', overflow: 'hidden' }}>
            <Grid item mb={2} mt={1}>
              <Grid container alignItems={'center'}>
                <Grid item flex={'1 1 auto'}>
                  <Autocomplete
                    value={consigneeValue || null}
                    onChange={(_, v) => handleConsigneesChange(v)}
                    disablePortal
                    getOptionLabel={i => i.title}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    disableClearable
                    size="small"
                    options={billPartnersStore?.consigneesList || []}
                    renderInput={params => <TextField {...params} label="Грузополучатель" />}
                    disabled
                  />
                </Grid>
                <Grid item>
                  <Box ml={'4px'} mt={'-1px'}>
                    <Link href={`/app/clients/${encodeURIComponent(consigneeValue?.customerCode)}`} noTypography>
                      <IconButton size="small" title="Перейти к грузополучателю" disabled={!consigneeValue?.code}>
                        <SearchRoundedIcon />
                      </IconButton>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item mb={2}>
              <Grid container alignItems={'center'}>
                <Grid item flex={'1 1 auto'}>
                  <Autocomplete
                    getOptionKey={option => option.code}
                    value={addressValue || null}
                    onChange={(_, v) => handleAddressChange(v)}
                    disablePortal
                    getOptionLabel={i => i.addressFull}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    disableClearable
                    size="small"
                    options={billPartnersStore?.addressesList || []}
                    renderInput={params => <TextField {...params} label="Адрес" />}
                    title={addressValue?.addressFull || ''}
                    disabled
                  />
                </Grid>
                <Grid item>
                  <Box ml={'4px'} mt={'-1px'}>
                    {addressValue ? (
                      <Link href={mapBtnUrl} target="_blank" noTypography>
                        <IconButton size="small" title={mapBtnTitle} disabled={!payerValue?.code}>
                          <NearMeTwoToneIcon />
                        </IconButton>
                      </Link>
                    ) : (
                      <IconButton size="small" title={'Выберете адрес для отображения маршрута'} disabled>
                        <NearMeTwoToneIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item mb={2}>
              <Grid container alignItems={'center'}>
                <Grid item flex={'1 1 auto'}>
                  <Autocomplete
                    value={payerValue || null}
                    onChange={(_, v) => handlePayerChange(v)}
                    disablePortal
                    getOptionLabel={i => i.title}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    disableClearable
                    size="small"
                    options={billPartnersStore?.payersList || []}
                    renderInput={params => <TextField {...params} label="Плательщик" />}
                    disabled={!hasNewPartnersAccess}
                  />
                </Grid>
                <Grid item>
                  <Box ml={'4px'} mt={'-1px'}>
                    <Link href={`/app/clients/${encodeURIComponent(payerValue?.customerCode)}`} noTypography>
                      <IconButton size="small" title="Перейти к плательщику" disabled={!payerValue?.code}>
                        <SearchRoundedIcon />
                      </IconButton>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TabSelectWrapper>
    );
  }
);
