import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/nextjs';
import {
  AvailableCatalogProductType,
  CatalogCategoriesItemViewResponse,
  CatalogProductsResponse,
  CatalogProductsSearchFilterResponse,
  ProductItemPrice,
  ProductProperty,
} from '../api/marketx';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { BaseSizesType, BaseSizesValue, CategoryViewState, ProductsNormalType } from '../slices/AppCatalog';
import { WarehouseListStore } from './WarehouseListStore';
import { RootStore } from './StoreManager';
import { AuthStore } from './AuthStore';
import { mapCategories } from '../slices/AppCatalog/lib';
import { DealItemStore } from './DealItemStore';
import { CatalogRootStore } from './CatalogRootStore';
import { RouterStore } from './RouterStore';
import { ApiStore } from './Global/ApiStore';
import { AgreementItemStore } from './Clients/AgreementItemStore';

const defaultPageSize = 30;
export const noWh = 'no-wh-mkm'; //* признак отсутствующего склада

/**
 * Вариант набора результирующих данных.
 * Только данные для вывода списка товаров, без отдельного словаря свойств.
 */
const productReturnSetProducts = 'products';

/**
 * Пустой список шкал цен.
 * Отдельная константа, чтобы возвращать одно и то же значение
 * и mobx не запускал ререндер.
 */
const emptyPriceRangesList = [];

export type SortType =
  | 'name'
  | 'cost'
  | 'mrc'
  | 'baseSize'
  | 'stockForSale'
  | 'reserve'
  | 'manufacturer'
  | 'cost20'
  | 'custodyUnitCost'
  | 'multiplicity'
  | 'multiplicityUnitCost'
  | 'inStockForSale'
  | 'inTransitQuantity';

export const stockFilterTypes = <const>['all', 'false', 'true'];
export type StockFilter = (typeof stockFilterTypes)[number];
export const inTransitFilterTypes = <const>['0', '1'];
export type InTransitFilter = (typeof inTransitFilterTypes)[number];

const TableModeTypes = <const>['price', 'catalog'];
type TableMode = (typeof TableModeTypes)[number];

export enum ColumnsMode {
  SHORT = 'short',
  WIDE = 'wide',
}

const FilterNameTypes = <const>[
  'manufacturers',
  'stockFilter',
  'baseSizes',
  'productTypes',
  'stockRequired',
  'inTransitRequired',
  'inReserveRequired',
  'properties',
];
export type FilterNames = (typeof FilterNameTypes)[number];

export const FilterNameTitles: Record<FilterNames, string> = {
  manufacturers: 'Производитель',
  stockFilter: 'без учета остатков',
  stockRequired: 'без учета остатков',
  inTransitRequired: 'в пути',
  inReserveRequired: 'в резерве',
  productTypes: 'Виды товаров',
  baseSizes: '',
  properties: 'Свойство',
};
export const TABLE_MODE_CODES = {
  price: 'price' as const,
  catalog: 'catalog' as const,
};
export const FILTER_NAME_CODES = {
  manufacturers: 'manufacturers' as FilterNames,
  stockFilter: 'stockFilter' as FilterNames,
  stockRequired: 'stockRequired' as FilterNames,
  inTransitRequired: 'inTransitRequired' as FilterNames,
  inReserveRequired: 'inReserveRequired' as FilterNames,
  vat: 'vat' as FilterNames,
  baseSizes: 'baseSizes' as FilterNames,
  productTypes: 'productTypes' as FilterNames,
  properties: 'properties' as FilterNames,
};
// enum FilterNames {
//   BaseSizes = 'baseSizes',
//   Manufacturers = 'manufacturers',
//   StockFilter = 'stockFilter',
// }
export interface Filter {
  type: FilterNames;
  code?: string;
  updateList?: boolean;
  label: string;
  group?: {
    label: string;
    code?: string;
  };
}

const getCheckedMap = (checked: string[]): Record<string, boolean> => {
  const isChecked = {};

  if (!checked) {
    return isChecked;
  }

  checked.forEach(code => {
    isChecked[code] = true;
  });

  return isChecked;
};

const mapProductTypes = (productTypes: AvailableCatalogProductType[], checked: string[]): CatalogProductTypeShortItem[] => {
  const result: CatalogProductTypeShortItem[] = [];
  if (!productTypes) {
    return result;
  }
  const isChecked = getCheckedMap(checked);
  productTypes.forEach(productType => {
    result.push({
      code: productType.code,
      title: productType.value || '(Без названия)',
      isChecked: !!isChecked[productType.code],
      productsQuantity: productType.productsQuantity,
    });
  });

  result.sort((a, b) => (a.title > b.title ? 1 : -1));
  return result;
};

const mapFiltersProperties = (properties: ProductProperty[], checkedValues: string[]): CatalogPropertyShortItem[] => {
  const result = new Array<CatalogPropertyShortItem>();
  if (!properties) {
    return result;
  }
  const isChecked = getCheckedMap(checkedValues);
  properties.forEach(p => {
    const prop = <CatalogPropertyShortItem>{
      code: p.code,
      title: p.title,
      typeCode: p.typeCode,
      values: [],
    };
    p.values?.forEach(v => {
      prop.values.push(<CatalogPropertyValueShortItem>{
        code: v.code,
        value: v.value,
        isChecked: !!isChecked[v.code],
        productsQuantity: Number(v.productsQuantity) || 0,
      });
    });
    // Сортировать здесь -- ошибка, у бэкенда больше возможностей
    // по определению правильного порядка, пусть присылает отсортированное.
    // prop.values.sort((v1, v2) => {
    //   const n1 = Number(v1.value);
    //   const n2 = Number(v2.value);
    //   if (isNaN(n1) || isNaN(n2)) {
    //     return v1.value.localeCompare(v2.value);
    //   }
    //   return n1 - n2;
    // });
    result.push(prop);
  });

  return result;
};

// Собирает производителей из разделов
// checked -- коды выбранных производителей
export const getCategoriesManufacturers = (productCategories: CategoryViewState, checked: string[]): ManufacturerShortItem[] => {
  const result: ManufacturerShortItem[] = [];
  if (!productCategories) {
    return result;
  }

  const map = {};
  const isChecked = getCheckedMap(checked);

  productCategories.codes.forEach(catCode => {
    Object.keys(productCategories.byCode[catCode].manufacturersByCode).forEach(code => {
      if (!map[code]) {
        const cat = productCategories.byCode[catCode];
        result.push({
          code: cat.manufacturersByCode[code].code,
          title: cat.manufacturersByCode[code].title || '(Без названия)',
          isChecked: !!isChecked[code],
          productsQuantity: cat.manufacturersByCode[code].quantity,
        });
        map[code] = true;
      }
    });
  });

  result.sort((a, b) => (a.title > b.title ? 1 : -1));
  return result;
};

export type CatalogRequest = {
  // текущий раздел
  categoryCode?: string;
  // строка для поиска
  searchQuery?: string;
  // Фильтр по базовому размеру
  baseSizeCodes?: string[];
  // фильтр по производителям
  manufacturerCodes?: string[];
  // свойства
  propertiesValuesCodes?: string[];
  // виды товаров
  productTypeCodes?: string[];
  // склад
  warehouseCode?: string;
  /**
   * false = без НДС
   * true = c НДС
   */
  vat?: boolean;
  /**
   * фильтр по остаткам
   */
  stockFilter: StockFilter;

  page?: number;
  count?: number;
  sort?: string;
  agreementCode?: string;

  /**
   * Закладка поискового запроса
   */
  searchMarker?: string;
};

export type ManufacturerShortItem = {
  code: string;
  title: string;
  productsQuantity: number;
  isChecked: boolean;
};
export type CatalogProductTypeShortItem = {
  code: string;
  title: string;
  productsQuantity: number;
  isChecked: boolean;
};

export interface CatalogPropertyShortItem {
  code: string;
  title: string;
  typeCode: string; // Тип свойства для отображения: число, строка, вещественное.
  values: CatalogPropertyValueShortItem[];
}

export interface CatalogPropertyValueShortItem {
  code: string;
  value: string;
  isChecked: boolean;
  productsQuantity: number;
}

export class CatalogStore {
  svc: CatalogService;
  rootStore: RootStore;
  apiStore: ApiStore;
  authStore: AuthStore;
  routerStore: RouterStore;

  /**
   * Сделка. Указана если каталог выводится для добавления товаров в сделку
   */
  dealStore?: DealItemStore = null;
  /**
   * Соглашение. Указана если каталог выводится для добавления товаров в сделку
   */
  agreementStore?: AgreementItemStore = null;

  /**
   * Управляем адресом страницы
   */
  routerControlEnabled = false;

  /**
   * фильтры Размера, производителя и фильтр наличия
   */
  filters: Filter[] = [];

  request: CatalogRequest = {
    stockFilter: 'true',
    manufacturerCodes: [],
    productTypeCodes: [],
    propertiesValuesCodes: [],
    baseSizeCodes: [],
    vat: true,
    sort: '',
    searchQuery: '',
    warehouseCode: '',
    agreementCode: '',
  };

  /**
   * Метка поиска последнего запроса товаров.
   * Позволяет получать фильтры к полученным товарам.
   */
  lastResponseSearchMarker = '';

  /**
   * В процессе перехода к другому узлу каталога или поиску
   */
  isNavigating = false;

  /**
   * Игнорировать полученные данные по товарам, если запрос был отправлен
   * ранее указанного времени. Необходимо для решения проблем с конкурентными
   * запросами.
   */
  ignoreBeforeDateProducts?: Date;

  /**
   * Игнорировать полученные данные по фильтрам, если запрос был отправлен
   * ранее указанного времени. Необходимо чтобы фильтры отображались
   * именно для тех товаров, которые сейчас отображаются в списке.
   */
  ignoreBeforeDateFilters?: Date;

  isProductsLoaded = false;
  isCatalogLoaded = false;
  isFiltersLoading = false;
  isFiltersLoaded = false;

  isLoading = false;
  isMoreLoading = false;
  loadedEpoch = 0;
  // можно загрузить следующую страницу
  hasMore = true;

  baseSizeList: BaseSizesType[] = [];
  products: ProductsNormalType[] = [];
  categories: CategoryViewState = {
    codes: [],
    byCode: {},
    maxLevel: 0,
  };
  manufacturers: ManufacturerShortItem[] = [];
  properties: CatalogPropertyShortItem[] = [];
  url = '/app/catalog-old';
  productTypes: CatalogProductTypeShortItem[] = [];

  onNavigate: (url: string) => void;

  storeIdentifier = 1;
  tableMode: TableMode = 'catalog';

  columnsMode = ColumnsMode.SHORT;

  constructor(rootStore: RootStore) {
    this.svc = catalogServiceInstance;
    this.apiStore = rootStore.getApiStore();
    this.rootStore = rootStore;
    this.routerStore = rootStore.getRouter();
    this.authStore = rootStore.getAuth();
    this.storeIdentifier = rootStore.nextStoreNumber();
    // фикс для безопасной подстановки в InfiniteScroll
    this.loadMore = this.loadMore.bind(this);
    makeAutoObservable(this, {
      svc: false,
      apiStore: false,
      rootStore: false,
      authStore: false,
      // dealStore: false,
      // agreementStore: false,
    });
  }
  setUrl(url: string): void {
    this.url = url;
  }
  setTableMode(mode: TableMode): void {
    this.tableMode = mode;
  }
  actualizeRouter(req: CatalogRequest): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();
    if (this.dealStore?.dealCode) {
      params.set('deal', this.dealStore?.dealCode);
    }
    if (this.agreementStore?.agreementCode) {
      params.set('agreement', this.agreementStore?.agreementCode);
    }
    if (req.vat === false) {
      params.set('vat', String(req.vat));
    }
    if (req.searchQuery) {
      params.set('query', req.searchQuery);
    }
    if (req.manufacturerCodes?.length) {
      params.set(FILTER_NAME_CODES.manufacturers, req.manufacturerCodes.join(','));
    } else {
      params.delete(FILTER_NAME_CODES.manufacturers);
    }
    if (req.productTypeCodes?.length) {
      params.set(FILTER_NAME_CODES.productTypes, req.productTypeCodes.join(','));
    } else {
      params.delete(FILTER_NAME_CODES.productTypes);
    }
    if (req.propertiesValuesCodes?.length) {
      params.set(FILTER_NAME_CODES.properties, req.propertiesValuesCodes.join(','));
    } else {
      params.delete(FILTER_NAME_CODES.properties);
    }
    if (req.warehouseCode?.length) {
      params.set('warehouseCode', req.warehouseCode);
    } else {
      params.delete('warehouseCode');
    }
    if (req.stockFilter === 'all') {
      params.set(FILTER_NAME_CODES.stockFilter, req.stockFilter);
    } else {
      params.delete(FILTER_NAME_CODES.stockFilter);
    }
    if (req.baseSizeCodes?.length) {
      params.set(FILTER_NAME_CODES.baseSizes, req.baseSizeCodes.join(','));
    } else {
      params.delete(FILTER_NAME_CODES.baseSizes);
    }
    if (req.agreementCode) {
      params.set('agreementCode', req.agreementCode);
    }
    // params.set('csid', String(this.storeIdentifier));
    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url: string;
    if (req.categoryCode) {
      url = `${this.url}/${encodeURIComponent(req.categoryCode)}`;
    } else if (req.searchQuery) {
      url = `${this.url}/search`;
    } else {
      url = `${this.url}`;
    }
    const isOwnUrl = this.routerStore.asPath().indexOf(this.url) !== -1; //* фикс MSOMS-2229
    if (url && isOwnUrl) {
      url += paramsStr;
      this.routerStore.replace(url, undefined, { shallow: true });
    }
  }

  /**
   * Количество видимых колонок таблицы, чтобы содержимое рояля
   * занимало всю ширину.
   */
  productsListColumnsCount(): number {
    let columnsCount = 7;
    const priceRanges = this.productsListVisiblePriceRanges();
    switch (this.columnsMode) {
      case ColumnsMode.WIDE:
        columnsCount = 9;
        if (priceRanges.length) {
          columnsCount = columnsCount - 1 + priceRanges.length;
        }
        break;
    }
    return columnsCount + Number(this.isAddMode);
  }

  /**
   * Количество колонок в номенклатуре, чтобы позиция остатков
   * совпадало с остатками артикула.
   */
  productsListNomenclatureColSpan(): number {
    const priceRanges = this.productsListVisiblePriceRanges();
    switch (this.columnsMode) {
      case ColumnsMode.WIDE:
        if (priceRanges.length) {
          return 7 - 1 + priceRanges.length;
        }
        return 7;
    }
    return 5;
  }

  productsListVisiblePriceRanges(): ProductItemPrice[] {
    if (this.columnsMode !== ColumnsMode.WIDE) {
      return emptyPriceRangesList;
    }
    if (!this.isProductsLoaded) {
      return emptyPriceRangesList;
    }
    if (!this.products.length) {
      return emptyPriceRangesList;
    }
    for (let i = 0; i < this.products?.length; i++) {
      if (this.products[i].prices?.length) {
        return this.products[i].prices;
      }
    }
    return emptyPriceRangesList;
  }

  /**
   * Возвращает код филиала (для подключения цен), если это необходимо.
   */
  getBranchOfficeCode(): string | undefined {
    return this.dealStore?.deal?.branchOfficeCode || this.agreementStore?.agreement?.branchOfficeCode;
  }

  getWarehousesStore(): WarehouseListStore {
    return this.rootStore.getLocalStoreFor(this, 'WarehouseListStore', WarehouseListStore, store => {
      if (this.request.warehouseCode) {
        store.setActiveWarehouseCode(this.request.warehouseCode);
      }
      if (this.dealStore) {
        if (this.dealStore?.deal) {
          store.loadListForDeal(this.dealStore?.deal);
        }
      } else {
        store.loadForProfile();
      }
    });
  }
  /**
   * Отвечает за режим добавление каталога
   */
  get isAddMode(): boolean {
    return !!this.dealStore?.deal || !!this.agreementStore?.agreement;
  }
  get typeCurrentStoreAddMode(): 'agreement' | 'deal' {
    if (!!this.dealStore?.deal) {
      return 'deal';
    } else if (!!this.agreementStore?.agreement) {
      return 'agreement';
    } else {
      return 'deal';
    }
  }
  loadMore(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.isMoreLoading = true;
    this.request.page = (this.request?.page || 1) + 1;
    this.request.searchMarker = this.lastResponseSearchMarker || undefined;
    this.svc.executeRequest(this);
  }

  getCatalogRootStore(stockFilter?: StockFilter): CatalogRootStore {
    return this.rootStore.getLocalStoreFor(this, 'CatalogRootStore', CatalogRootStore, store => {
      store.loadForWarehouse(this.getWarehousesStore(), stockFilter);
    });
  }

  setIsNavigating(navigating: boolean): void {
    this.isNavigating = navigating;
  }

  setDealStore(dealStore?: DealItemStore): void {
    this.dealStore = dealStore;
  }
  setAgreementStore(store?: AgreementItemStore): void {
    this.agreementStore = store;
  }

  setCategorySearchParam(categoryCode: string, searchQuery = '', params: { [key: string]: string }): void {
    this.lastResponseSearchMarker = '';
    this.request.searchMarker = undefined;
    this.request.categoryCode = categoryCode;
    this.request.searchQuery = searchQuery;
    if (params[FILTER_NAME_CODES.manufacturers]) {
      this.request.manufacturerCodes = params[FILTER_NAME_CODES.manufacturers].split(',');
    }
    if (params[FILTER_NAME_CODES.productTypes]) {
      this.request.productTypeCodes = params[FILTER_NAME_CODES.productTypes].split(',');
    }
    if (params[FILTER_NAME_CODES.properties]) {
      this.request.propertiesValuesCodes = params[FILTER_NAME_CODES.properties].split(',');
    }
    if (params[FILTER_NAME_CODES.baseSizes]) {
      this.request.baseSizeCodes = params[FILTER_NAME_CODES.baseSizes].split(',');
    }
    if (params['warehouseCode']) {
      // if (params['warehouseCode'] && this.authStore.profile?.warehouseCode !== params['warehouseCode']) {
      this.request.warehouseCode = params['warehouseCode'];
    }
    if (params['vat'] === 'false') {
      this.request.vat = false;
    }
    if (params[FILTER_NAME_CODES.stockFilter] && stockFilterTypes.includes(params[FILTER_NAME_CODES.stockFilter] as StockFilter)) {
      this.request.stockFilter = params[FILTER_NAME_CODES.stockFilter] as StockFilter;
    }
    if (params['agreementCode']) {
      this.request.agreementCode = params['agreementCode'];
    }
    this.debounceListReplace();
  }
  get selectedValueByWarehouse(): string {
    return this.request.warehouseCode || this.authStore.profile?.warehouseCode;
  }
  setCategorySearch(categoryCode: string, searchQuery: string): void {
    // if (searchQuery) {
    //   categoryCode = '';
    // }
    if (this.request.categoryCode === categoryCode && this.request.searchQuery === searchQuery) {
      return;
    }
    this.request.categoryCode = categoryCode;
    this.resetAllFilter(true);
    if (!(this.isLoading || this.svc.requestDebounceTimeout) || searchQuery === '') {
      this.request.searchQuery = searchQuery;
      this.actualizeRouter(this.request);
    }

    this.loadedEpoch++;
    this.debounceListReplace();
  }

  cleanUpFilters(): void {
    this.filters = [];
  }

  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }

  setSearchQuery(query: string): void {
    if (this.request.searchQuery === query) {
      return;
    }
    if (!query || query.length >= 3) {
      this.lastResponseSearchMarker = undefined;
      this.request.page = undefined;
      this.request.searchQuery = query;
      this.request.searchMarker = undefined;
      this.svc.debounceRequest(this);
    }
  }

  // setStockFilter(value: StockFilter): void {
  //   if (this.request.stockFilter === value) {
  //     return;
  //   }
  //   this.debounceListReplace();
  // }

  setWarehouse(warehouseCode?: string): void {
    if (warehouseCode && warehouseCode.indexOf(noWh) >= 0 && warehouseCode !== this.request.warehouseCode) {
      if (this.request.stockFilter !== 'all') {
        this.toggleStock(false);
      }
    }
    this.request.warehouseCode = warehouseCode;
    // if (this.request.warehouseCode.indexOf(noWh) >= 0) {
    // this.toggleStock(false);
    // }
    this.debounceListReplace();
  }
  /**
   * Сброс categories baseSize manufacturer
   **/
  resetCatalog(): void {
    this.categories = {
      codes: [],
      byCode: {},
      maxLevel: 0,
    };
    this.request.baseSizeCodes = [];
    this.request.manufacturerCodes = [];
    this.request.productTypeCodes = [];
    this.request.categoryCode = undefined;
    this.baseSizeList = [];
    this.manufacturers = [];
    this.productTypes = [];
    this.properties = [];
    this.request.searchQuery = '';
    this.request.sort = '';
    this.loadedEpoch++;
    this.isLoading = false;
    this.isProductsLoaded = false;
  }

  setProductsResult(ctx: AxiosCallContext, req: CatalogRequest, res: CatalogCategoriesItemViewResponse): void {
    if (this.ignoreBeforeDateProducts && this.ignoreBeforeDateProducts.getTime() > ctx.startTime.getTime()) {
      return;
    }
    this.ignoreBeforeDateProducts = ctx.startTime;
    this.ignoreBeforeDateFilters = ctx.startTime;

    const requestBaseSizeCodes = new Map<string, boolean>();
    // ? подставляя сюда this.request вместо req, мы выставляем новые фильтры даже если запрос был перевызван
    // ? example отправили первый запрос с определенным request, отправляем еще один изменив значение в фильтрах и тем самым создав новый request, по которым как раз и формируем данные для старого запроса.
    this.request.baseSizeCodes?.map(code => {
      requestBaseSizeCodes.set(code, true);
    });

    if (this.isMoreLoading) {
      this.products.push(...(res?.products || []));
    } else {
      this.lastResponseSearchMarker = res?.searchMarker;
      this.products.splice(0);
      this.products.push(...(res?.products || []));
      this.baseSizeList.splice(0);
      this.baseSizeList.push(...(res?.baseSizes || []));
      for (let i = 0; i < this.baseSizeList.length; i++) {
        const group = this.baseSizeList[i];
        for (let j = 0; j < group.values.length; j++) {
          const value = group.values[j];
          if (Boolean(requestBaseSizeCodes.get(value.code))) {
            value.isChecked = true;
          }
        }
      }
      // const categories = mapCategories(res.categories, this.request.categoryCode || undefined, res.manufacturers, res.properties);
      this.categories = mapCategories(res.categories, req.categoryCode || undefined, res.manufacturers);
      this.manufacturers = getCategoriesManufacturers(this.categories, this.request.manufacturerCodes);
      this.productTypes = mapProductTypes(res.productTypes, this.request.productTypeCodes);
      this.reloadFilters();
    }
    if (!this.isProductsLoaded) {
      if (this.request.stockFilter === 'all') {
        this.addFilter({
          type: FILTER_NAME_CODES.stockFilter,
          label: FilterNameTitles.stockFilter,
        });
      } else if (this.request.warehouseCode.indexOf(noWh) >= 0) {
        this.toggleStock(false);
      }

      for (let i = 0; i < this.baseSizeList.length; i++) {
        const group = this.baseSizeList[i];
        for (let j = 0; j < group.values.length; j++) {
          const value = group.values[j];
          if (Boolean(requestBaseSizeCodes.get(value.code))) {
            value.isChecked = true;
            this.addFilter({
              type: FILTER_NAME_CODES.baseSizes,
              code: value.code,
              label: value.value,
              group: {
                label: group.groupName,
                code: group.groupCode,
              },
            });
          }
        }
      }
      this.manufacturers
        .filter(manufacturer => manufacturer.isChecked)
        .forEach(manufacturer => {
          this.addFilter({
            type: FILTER_NAME_CODES.manufacturers,
            code: manufacturer.code,
            label: manufacturer.title,
            group: {
              label: FilterNameTitles.manufacturers,
            },
          });
        });
      this.productTypes
        .filter(productType => productType.isChecked)
        .forEach(productType => {
          this.addFilter({
            type: FILTER_NAME_CODES.productTypes,
            code: productType.code,
            label: productType.title,
            group: {
              label: FilterNameTitles.productTypes,
            },
          });
        });
    }

    this.isProductsLoaded = true;
    this.isLoading = false;
    this.isMoreLoading = false;
    this.isCatalogLoaded = true;
    this.hasMore = res.products?.length >= req.count;
    this.loadedEpoch++;

    this.actualizeRouter(req);
    this.triggerNavigate();
  }

  /**
   * Установка или снятие фильтра по базовому размеру
   */
  toggleBaseSize(bsv: BaseSizesValue, enable: boolean, group: BaseSizesType): void {
    const filterName = FILTER_NAME_CODES.baseSizes;
    for (let i = 0; i < group.values.length; i++) {
      if (bsv.code === group.values[i].code) {
        group.values[i].isChecked = !!enable;
      }
    }

    const idx = this.request.baseSizeCodes?.indexOf(bsv.code);
    if (idx >= 0 === enable) {
      return;
    }
    if (idx >= 0) {
      this.deleteFilter(bsv.code, 'code');
      this.request.baseSizeCodes.splice(idx, 1);
    } else {
      this.addFilter({
        type: filterName,
        code: bsv.code,
        label: bsv.value,
        group: {
          label: group.groupName,
          code: group.groupCode,
        },
      });
      if (this.request.baseSizeCodes) {
        this.request.baseSizeCodes.push(bsv.code);
      } else {
        this.request.baseSizeCodes = [bsv.code];
      }
    }
    this.debounceListReplace();
  }

  /**
   * Установка колонки сортировки или изменение направления
   * @param column
   */
  toggleSortColumn(column: SortType | string): void {
    if (this.request?.sort === column) {
      this.request.sort = '-' + column;
    } else {
      this.request.sort = column;
    }
    this.debounceListReplace();
  }

  toggleColumnsMode(): void {
    if (this.columnsMode === ColumnsMode.WIDE) {
      this.columnsMode = ColumnsMode.SHORT;
    } else {
      this.columnsMode = ColumnsMode.WIDE;
    }
  }

  toggleManufacturerFilterInclude(value: ManufacturerShortItem, enable: boolean): void {
    const filterName = FILTER_NAME_CODES.manufacturers;
    this.manufacturers.forEach(m => {
      if (m.code === value.code) {
        m.isChecked = enable;
      }
    });
    if (!this.request.manufacturerCodes) {
      if (!enable) {
        return;
      }
      this.request.manufacturerCodes.push(value.code);
      this.addFilter({
        type: filterName,
        code: value.code,
        label: value.title,
        group: {
          label: FilterNameTitles.manufacturers,
        },
      });
    } else {
      const index = this.request.manufacturerCodes.indexOf(value.code);
      if (index >= 0 === enable) {
        return;
      }
      if (index >= 0) {
        this.request.manufacturerCodes.splice(index, 1);
        this.deleteFilter(value.code, 'code');
      } else {
        this.request.manufacturerCodes.push(value.code);
        this.addFilter({
          type: filterName,
          code: value.code,
          label: value.title,
          group: {
            label: FilterNameTitles.manufacturers,
          },
        });
      }
    }
    this.debounceListReplace();
  }
  toggleProductTypesFilterInclude(value: CatalogProductTypeShortItem, enable: boolean): void {
    const filterName = FILTER_NAME_CODES.productTypes;
    this.productTypes.forEach(m => {
      if (m.code === value.code) {
        m.isChecked = enable;
      }
    });
    if (!this.request.productTypeCodes) {
      if (!enable) {
        return;
      }
      this.request.productTypeCodes.push(value.code);
      this.addFilter({
        type: filterName,
        code: value.code,
        label: value.title,
        group: {
          label: FilterNameTitles.productTypes,
        },
      });
    } else {
      const index = this.request.productTypeCodes.indexOf(value.code);
      if (index >= 0 === enable) {
        return;
      }
      if (index >= 0) {
        this.request.productTypeCodes.splice(index, 1);
        this.deleteFilter(value.code, 'code');
      } else {
        this.request.productTypeCodes.push(value.code);
        this.addFilter({
          type: filterName,
          code: value.code,
          label: value.title,
          group: {
            label: FilterNameTitles.productTypes,
          },
        });
      }
    }
    this.debounceListReplace();
  }

  togglePropertyValueFilterInclude(prop: CatalogPropertyShortItem, value: CatalogPropertyValueShortItem, enable: boolean): void {
    const filterName = FILTER_NAME_CODES.properties;
    value.isChecked = enable;
    const index = this.request.propertiesValuesCodes?.indexOf(value.code);
    if (index >= 0 === enable) {
      return;
    }
    if (index >= 0) {
      this.request.propertiesValuesCodes.splice(index, 1);
      this.deleteFilter(value.code, 'code');
    } else {
      this.request.propertiesValuesCodes.push(value.code);
      this.addFilter({
        type: filterName,
        code: value.code,
        label: value.value,
        group: {
          label: prop.title,
        },
      });
    }
    this.debounceListReplace();
  }

  toggleStock(filter: boolean): void {
    const filterName = FILTER_NAME_CODES.stockFilter;
    this.request.stockFilter = filter ? 'true' : 'all';
    const [elem] = this.filters.filter(i => i.type === filterName);

    if (!elem && !filter) {
      this.addFilter({
        type: filterName,
        label: FilterNameTitles.stockFilter,
      });
    } else {
      this.deleteFilter(filterName, 'type');
    }

    this.debounceListReplace();
  }

  addFilter(value: Filter): void {
    this.filters.push(value);
  }

  deleteFilter(code: string, property: string): void {
    this.filters = this.filters.filter(i => i[property] !== code);
  }
  /**
   * очищаем выбранные фильтры и очищаем request
   */
  resetAllFilter(isStockFilterSave?: boolean): void {
    const stockFilterIndex = this.filters.findIndex(val => val.type === 'stockFilter');
    if (stockFilterIndex !== -1) {
      this.filters = this.filters.slice(stockFilterIndex, stockFilterIndex + 1);
    } else {
      this.filters = [];
    }
    this.request.baseSizeCodes = [];
    this.request.manufacturerCodes = [];
    this.request.productTypeCodes = [];
    this.request.propertiesValuesCodes = [];
    this.request.stockFilter = isStockFilterSave ? this.request.stockFilter : 'true';
    // сбрасывает склад при переходе на категорию, если зашли НЕ из сделки.
    // if (!this.dealStore?.dealCode) {
    //   this.request.warehouseCode = '';
    // }
  }
  resetFilter(value: Filter, updateList = true): void {
    if (value.type === FILTER_NAME_CODES.stockFilter) {
      this.request.stockFilter = 'true';
    }
    if (value.type === FILTER_NAME_CODES.vat) {
      this.request.vat = true;
    }
    if (value.type === FILTER_NAME_CODES.manufacturers) {
      this.request.manufacturerCodes = this.request.manufacturerCodes.filter(i => i !== value.code);
    }
    if (value.type === FILTER_NAME_CODES.productTypes) {
      this.request.productTypeCodes = this.request.productTypeCodes.filter(i => i !== value.code);
    }
    if (value.type === FILTER_NAME_CODES.baseSizes) {
      this.request.baseSizeCodes = this.request.baseSizeCodes.filter(i => i !== value.code);
      for (let i = 0; i < this.baseSizeList.length; i++) {
        const group = this.baseSizeList[i];
        for (let j = 0; j < group.values.length; j++) {
          const valueGroup = group.values[j];
          if (value.code === valueGroup.code) {
            valueGroup.isChecked = !valueGroup.isChecked;
          }
        }
      }
    }
    if (value.type === FILTER_NAME_CODES.properties) {
      this.request.propertiesValuesCodes = this.request.propertiesValuesCodes.filter(i => i !== value.code);
    }
    this.deleteFilter(value.code, 'code');
    if (updateList) {
      this.svc.debounceRequest(this);
    } else {
      this.actualizeRouter(this.request);
    }
  }
  triggerNavigate(): void {
    if (!this.onNavigate) {
      return;
    }
    let url = this.url + '/' + (this.request.categoryCode || 'search');
    if (this.request.searchQuery) {
      url += '?query=' + encodeURIComponent(this.request.searchQuery);
    }
    this.onNavigate(url);
  }

  /**
   * Возвращается на первую страницу и перезагружает список товаров
   */
  debounceListReplace(): void {
    this.isMoreLoading = false;
    this.lastResponseSearchMarker = undefined;
    this.request.page = undefined;
    this.request.searchMarker = undefined;
    this.svc.debounceRequest(this);
  }

  productPriceOfRange(product: ProductsNormalType, priceRangeCode: string | undefined): ProductItemPrice | undefined {
    if (!priceRangeCode) {
      return undefined;
    }
    for (let i = 0; i < product.prices?.length; i++) {
      if (product.prices[i].rangeCode === priceRangeCode) {
        return product.prices[i];
      }
    }
    return undefined;
  }

  /**
   * Перезагрузка списка свойств (фильтров)
   */
  reloadFilters(): void {
    this.isFiltersLoading = true;
    const req = Object.assign({}, this.request);
    const baseSizeCodes = req.baseSizeCodes?.join(',') || undefined;
    const manufacturersCodes = req.manufacturerCodes?.join(',') || undefined;
    const productTypeCodes = req.productTypeCodes?.join(',') || undefined;
    const propertiesValuesCodes = req.propertiesValuesCodes?.join(';') || undefined;
    const stockFilter = req.stockFilter && req.stockFilter !== 'all' ? req.stockFilter : undefined;

    this.apiStore
      .apiClientCatalog()
      .catalogProductsSearchFilter(
        undefined,
        req.searchQuery || undefined,
        req.categoryCode || undefined,
        propertiesValuesCodes,
        manufacturersCodes,
        baseSizeCodes,
        req.warehouseCode || undefined,
        stockFilter,
        this.getBranchOfficeCode(),
        productTypeCodes,
        String(this.lastResponseSearchMarker)
      )
      .then((res: AxiosResponse<CatalogProductsSearchFilterResponse>): void => {
        this.setFiltersResult(getCallContext(res), req, res.data);
      });
  }

  setFiltersResult(ctx: AxiosCallContext, req: CatalogRequest, res: CatalogProductsSearchFilterResponse): void {
    if (this.ignoreBeforeDateFilters && ctx.startTime.getTime() < this.ignoreBeforeDateFilters.getTime()) {
      return;
    }
    this.properties = mapFiltersProperties(res.properties, req.propertiesValuesCodes);
    if (!this.isFiltersLoaded) {
      this.properties.forEach(property =>
        property.values
          .filter(value => value.isChecked)
          .forEach(propertyValue => {
            this.addFilter({
              type: FILTER_NAME_CODES.properties,
              code: propertyValue.code,
              label: propertyValue.value,
              group: {
                label: property.title,
              },
            });
          })
      );
    }

    this.isFiltersLoading = false;
    this.isFiltersLoaded = true;
  }

  downloadPriceList(): void {
    this.svc.downloadPriceList(this);
  }
}

let catalogServiceCounter = 0;

class CatalogService {
  requestDebounceTimeout: NodeJS.Timeout;
  svcIndex = 0;

  constructor() {
    this.svcIndex = ++catalogServiceCounter;
  }

  debounceRequest(store: CatalogStore): void {
    clearTimeout(this.requestDebounceTimeout);
    this.requestDebounceTimeout = setTimeout(() => {
      this.requestDebounceTimeout = undefined;
      this.executeRequest(store);
      // }, 65500);
    }, 300);
  }

  executeRequest(store: CatalogStore): void {
    if (this.requestDebounceTimeout) {
      clearTimeout(this.requestDebounceTimeout);
      this.requestDebounceTimeout = null;
    }
    runInAction(() => {
      if (store.isLoading) {
        // if (store.isLoading || this.requestDebounceTimeout) {
        store.ignoreBeforeDateProducts = new Date();
        store.ignoreBeforeDateFilters = store.ignoreBeforeDateProducts;
      }
      store.isLoading = true;
    });
    const req = toJS(store.request);
    req.page = req.page > 1 ? req.page : undefined;
    req.count = req.count || defaultPageSize;
    const baseSizeCodes = req.baseSizeCodes?.join(',') || undefined;
    const manufacturersCodes = req.manufacturerCodes?.join(',') || undefined;
    const productTypeCodes = req.productTypeCodes?.join(',') || undefined;
    const propertiesValuesCodes = req.propertiesValuesCodes?.join(';') || undefined;
    const stockFilter = req.stockFilter && req.stockFilter !== 'all' ? req.stockFilter : undefined;

    const sentryTrx = Sentry.startTransaction({ name: 'CatalogStore.executeRequest' });

    if (!req.searchQuery && req.categoryCode && req.categoryCode !== 'search') {
      let sentrySpan = sentryTrx.startChild({
        op: 'apiClientCatalog.catalogCategoriesItemView.request',
        data: {
          categoryCode: req.categoryCode,
          manufacturersCodes,
          productTypeCodes,
          baseSizeCodes,
          stockFilter,
        },
      });
      store.apiStore
        .apiClientCatalog()
        .catalogCategoriesItemView(
          req.categoryCode,
          propertiesValuesCodes,
          manufacturersCodes,
          productTypeCodes,
          baseSizeCodes,
          req.warehouseCode || undefined,
          stockFilter,
          req.page,
          defaultPageSize,
          req.sort || undefined,
          store.getBranchOfficeCode(),
          productReturnSetProducts,
          req.searchMarker || undefined,
          undefined,
          req.agreementCode || undefined
        )
        .then((res: AxiosResponse<CatalogCategoriesItemViewResponse>) => {
          sentrySpan.finish();
          sentrySpan = sentryTrx.startChild({ op: 'apiClientCatalog.catalogCategoriesItemView.response' });
          store.setProductsResult(getCallContext(res), req, res.data);
        })
        .finally(() => {
          sentrySpan.finish();
          sentryTrx.finish();
        });
    } else if (req.searchQuery || req.categoryCode) {
      let sentrySpan = sentryTrx.startChild({
        op: 'apiClientCatalog.catalogProducts.request',
        data: {
          searchQuery: req.searchQuery,
          categoryCode: req.categoryCode || undefined,
          manufacturersCodes,
          productTypeCodes,
          baseSizeCodes,
          stockFilter,
        },
      });
      store.apiStore
        .apiClientCatalog()
        .catalogProducts(
          undefined,
          req.searchQuery,
          req.categoryCode || undefined,
          propertiesValuesCodes,
          manufacturersCodes,
          productTypeCodes,
          baseSizeCodes,
          req.warehouseCode || undefined,
          stockFilter,
          req.page,
          defaultPageSize,
          req.sort || undefined,
          store.getBranchOfficeCode(),
          req.searchMarker || undefined
        )
        .then((res: AxiosResponse<CatalogProductsResponse>): void => {
          sentrySpan.finish();
          sentrySpan = sentryTrx.startChild({ op: 'apiClientCatalog.CatalogProducts.response' });
          store.setProductsResult(getCallContext(res), req, res.data);
        })
        .finally(() => {
          sentrySpan.finish();
          sentryTrx.finish();
        });
    } else {
      const sentrySpan = sentryTrx.startChild({ op: 'resetCatalog' }); // This function returns a Span
      store.resetCatalog();
      sentrySpan.finish(); // Remember that only finished spans will be sent with the transaction
      sentryTrx.finish(); // Finishing the transaction will send it to Sentry
    }
  }

  downloadPriceList(store: CatalogStore): void {
    const req = Object.assign({}, store.request);
    const baseSizeCodes = req.baseSizeCodes?.join(',') || undefined;
    const manufacturersCodes = req.manufacturerCodes?.join(',') || undefined;
    const productTypeCodes = req.productTypeCodes?.join(',') || undefined;
    const propertiesValuesCodes = req.propertiesValuesCodes?.join(';') || undefined;
    const stockFilter = req.stockFilter && req.stockFilter !== 'all' ? req.stockFilter : undefined;

    const sentryTrx = Sentry.startTransaction({ name: 'CatalogService.downloadPriceList' });
    let sentrySpan = sentryTrx.startChild({
      op: 'apiClientCatalog.catalogCategoriesItemPricelistXlsx.request',
      data: {
        categoryCode: req.categoryCode,
        manufacturersCodes,
        productTypeCodes,
        baseSizeCodes,
        stockFilter,
      },
    });

    store.apiStore
      .apiClientCatalog()
      .catalogCategoriesItemPricelistXlsx(
        req.categoryCode ? req.categoryCode : 'goods',
        propertiesValuesCodes,
        req.searchQuery,
        manufacturersCodes,
        productTypeCodes,
        baseSizeCodes,
        req.warehouseCode || undefined,
        stockFilter,
        req.sort || undefined,
        store.getBranchOfficeCode(),
        productReturnSetProducts,
        req.searchMarker || undefined,
        undefined,
        req.agreementCode || undefined,
        {
          responseType: 'blob',
        }
      )
      .then(response => {
        if (response.data instanceof Blob) {
          let filename = 'pricelist.xlsx';
          const headers = response.headers;
          const contentDisposition = headers['content-disposition'];

          if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);

            if (matches != null && matches[1]) {
              filename = decodeURI(matches[1].replace(/['"]/g, ''));
            }
          }
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }

        sentrySpan.finish();
        sentrySpan = sentryTrx.startChild({ op: 'apiClientCatalog.catalogCategoriesItemPricelistXlsx.response' });
      })
      .finally(() => {
        sentrySpan.finish();
        sentryTrx.finish();
      });
  }
}

/**
 * Пока не будет решена проблема с дублированием сторов,
 * следует использовать этот единый инстанс.
 */
const catalogServiceInstance = new CatalogService();
