import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TooltipForReserveList } from '../../../components/Product/Reserve/TooltipForReseveList';
import { ContractCostPosition } from '../../../api/marketx';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';

interface DealPositionAdditionalTabReservesProps {
  dealPosition?: AppDealPosition;
  agreementPosition?: ContractCostPosition;
  branchOfficeCode: string;
  documentNumber?: string;
}

export const DealPositionAdditionalTabReserves: FC<DealPositionAdditionalTabReservesProps> = observer(
  ({ dealPosition, branchOfficeCode, documentNumber, agreementPosition }): JSX.Element => {
    return (
      <TabSelectWrapper
        infiniteScrollId={`${agreementPosition?.product.code || dealPosition?.product.code}TabSelectWrapperScrollableReserveContainer`}
      >
        <Grid sx={{ height: '100%' }}>
          {dealPosition?.product?.hasReserves || !!agreementPosition ? (
            <TooltipForReserveList
              product={dealPosition?.product || agreementPosition?.product}
              branchOfficeCode={branchOfficeCode}
              documentNumber={documentNumber}
              warehouseCodes={dealPosition?.warehouseCode ? [dealPosition.warehouseCode] : agreementPosition ? undefined : undefined}
              mustRender={dealPosition?.warehouseCode}
            />
          ) : (
            <Typography sx={{ py: 1, px: 2, mb: 1 }} variant="body2" component="div">
              Резервов нет
            </Typography>
          )}
        </Grid>
      </TabSelectWrapper>
    );
  }
);
